<!--
 * @Author: 智客云网络科技
 * @Date: 2021-04-19 19:09:44 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:11:57
 * @Description: 修改用户名
 * @FilePath: \src\views\Frame\ProFile\UpdateUserName.vue
-->

<template>
  <el-dialog
    v-drag2anywhere
    modal-append-to-body
    :title="title"
    :visible.sync="isShow"
    width="600px"
    top="15vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="handleClose"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card shadow="never">
        <el-form ref="security">
          <el-form-item label="新用户名：">
            <el-input v-model="username" size="small"></el-input>
          </el-form-item>
        </el-form>

        <el-button
          size="small"
          type="primary"
          icon="el-icon-check"
          @click="_UpdateUsername()"
          >保存</el-button
        >
        <el-button size="small" icon="el-icon-close" @click="isShow = false"
          >取消</el-button
        >
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import { updateMyUserName } from "./api"; //页面专有接口
export default {
  data() {
    return {
      username: "", //用户名
      title: "修改用户名", //弹窗标题
      isShow: false,
    };
  },
  methods: {
    // 打开
    open() {
      this.isShow = true;
    },

    //修改用户名
    _UpdateUsername() {
      const params = {
        username: this.username,
      };
      updateMyUserName(params)
        .then((res) => {
          this.$store.commit("SET_USERNAME", this.username); //用户名
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //关闭
    handleClose() {
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>

<style></style>
