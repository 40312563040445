/*
 * @Author: 智客云网络科技
 * @Date: 2021-04-19 11:07:53 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:07:14
 * @Description: 页面专属接口管理
 * @FilePath: \src\views\Frame\ProFile\api.js
 */

import http from "@/api";

// 获取账号信息
export const getAccountInfoByUser = (params) =>
  http.get("/v1/Account/GetAccountInfoByUser", params);

// 修改账号基础信息
export const updateAccountBasicsInfo = (params) =>
  http.put("/v1/Account/UpdateBasicsInfo", params);

// 修改密码
export const updateMyPassword = (params) =>
  http.put("/v1/Account/UpdatePassword", params);

// 修改用户名
export const updateMyUserName = (params) =>
  http.put("/v1/Account/UpdateUsername", params);

// 修改头像
export const updateMyAvatar = (params) =>
  http.put("/v1/Account/UpdateAvatar", params);
